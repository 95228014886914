import React, { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { div } from 'framer-motion/client'

export default function Home() {

  return (
    <div className='flex flex-col items-center justify-center'>
      <a
        className="flex items-center justify-center" 
        target="_blank" 
        rel="noopener noreferrer" 
        href="https://x.com/dagdotgg" 
      >
        <div className='flex items-center flex-col md:flex-row'>
          <motion.div
            initial={{ opacity: 0, y: 75, skew: 5, filter: 'blur(5px)' }}
            animate={{ opacity: 1, y: 0, skew: 0, filter: 'blur(0px)' }}
            transition={{ duration: 1, delay: 0.5, ease: 'backOut' }}
          >
            <svg
              viewBox="0 0 400 400"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-[200px] h-[200px] block -mt-16 md:hidden"
              preserveAspectRatio="none"
            >
              <g filter="url(#filter0_d_464_2815)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M162.224 306.881C147.029 315.72 128 304.72 128 287.096L128 90L160.561 109.602V267.874C160.561 268.859 161.625 269.476 162.479 268.987L278.101 202.828C278.976 202.327 278.958 201.06 278.069 200.585L223.019 171.147C222.165 170.691 221.132 171.31 221.132 172.278V203.037L188.572 222.639V153.809C188.572 136.548 206.902 125.509 222.084 133.627L310.927 181.135C326.7 189.569 327.102 212.107 311.639 221.101L162.224 306.881Z"
                  fill="#60E25E"
                />
              </g>
            </svg>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 150, skew: 5, filter: 'blur(5px)' }}
            animate={{ opacity: 1, x: 0, skew: 0, filter: 'blur(0px)' }}
            transition={{ duration: 1, delay: 0.5, ease: 'backOut' }}
          >
            <svg
              viewBox="0 0 400 400"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-[400px] h-[400px] hidden md:block"
              preserveAspectRatio="none"
            >
              <g filter="url(#filter0_d_464_2815)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M162.224 306.881C147.029 315.72 128 304.72 128 287.096L128 90L160.561 109.602V267.874C160.561 268.859 161.625 269.476 162.479 268.987L278.101 202.828C278.976 202.327 278.958 201.06 278.069 200.585L223.019 171.147C222.165 170.691 221.132 171.31 221.132 172.278V203.037L188.572 222.639V153.809C188.572 136.548 206.902 125.509 222.084 133.627L310.927 181.135C326.7 189.569 327.102 212.107 311.639 221.101L162.224 306.881Z"
                  fill="#60E25E"
                />
              </g>
            </svg>
          </motion.div>

          <div
            className='flex flex-col gap-5'
          >
            <div className="text-white text-center text-base md:text-left md:text-3xl font-bold">
              <motion.p
                initial={{ opacity: 0, y: 15, filter: 'blur(5px)' }}
                animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
                transition={{ duration: 0.3, delay: 0.8, ease: 'easeOut' }}
              >
                Onboarding the next million devs
              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: 15, filter: 'blur(5px)' }}
                animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
                transition={{ duration: 0.3, delay: 0.9, ease: 'easeOut' }}
              >
                to <div className='relative inline-block'>
                <motion.span>
                  build, earn, and innovate
                </motion.span>
                <motion.span className='absolute inset-0' style={{ filter: 'url(#glow-1)' }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.1, ease: 'easeOut' }}>
                  build, earn, and innovate
                </motion.span>
              </div> on-chain
              </motion.p>
            </div>
            <motion.div 
              initial={{ opacity: 0, y: 15, filter: 'blur(5px)' }}
              animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
              transition={{ duration: 0.3, delay: 1, ease: 'easeOut' }}
              className="flex items-center justify-center md:justify-start gap-2 text-[#cdcdcd] text-xs md:text-base"
            >
              <span>Follow us on</span>
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  d="M17.1761 3H19.9362L13.9061 10.6246L21 21H15.4456L11.0951 14.7074L6.11723 21H3.35544L9.80517 12.8446L3 3H8.69545L12.6279 8.75169L17.1761 3ZM16.2073 19.1723H17.7368L7.86441 4.73169H6.2232L16.2073 19.1723Z"
                  fill="currentColor"
                />
              </svg>
              <span>for updates</span>
            </motion.div>
          </div>
        </div>

        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          className='invisible absolute'
        >
          <defs>
            <filter
              id="filter0_d_464_2815"
              x="108.8"
              y="70.8"
              width="233.4"
              height="258.4"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="9.6" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0745098 0 0 0 0 0.996078 0 0 0 0 0.258824 0 0 0 0.8 0"
              />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_464_2815" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_464_2815" result="shape" />
            </filter>

            <filter id="glow-1" colorInterpolationFilters="sRGB" x="-50%" y="-50%" width="200%" height="250%">
                <feGaussianBlur in="SourceGraphic" data-target-blur="4" stdDeviation="4" result="blur4"/>
                <feGaussianBlur in="SourceGraphic" data-target-blur="19" stdDeviation="19" result="blur19"/>
                <feGaussianBlur in="SourceGraphic" data-target-blur="9" stdDeviation="9" result="blur9"/>
                <feGaussianBlur in="SourceGraphic" data-target-blur="30" stdDeviation="30" result="blur30"/>
                <feColorMatrix in="blur4" result="color-0-blur" type="matrix" values="1 0 0 0 0
                          0 0.9803921568627451 0 0 0
                          0 0 0.9647058823529412 0 0
                          0 0 0 0.8 0"/>
                <feOffset in="color-0-blur" result="layer-0-offsetted" dx="0" dy="0" data-target-offset-y="0"/>
                <feColorMatrix in="blur19" result="color-1-blur" type="matrix" values="0.38 0 0 0 0
                          0 0.89 0 0 0
                          0 0 0.37 0 0
                          0 0 0 1 0"/>
                <feOffset in="color-1-blur" result="layer-1-offsetted" dx="0" dy="2" data-target-offset-y="2"/>
                <feColorMatrix in="blur9" result="color-2-blur" type="matrix" values="0.1 0 0 0 0
                          0 0.89 0 0 0
                          0 0 0.36470588235294116 0 0
                          0 0 0 0.65 0"/>
                <feOffset in="color-2-blur" result="layer-2-offsetted" dx="0" dy="2" data-target-offset-y="2"/>
                <feMerge>
                    <feMergeNode in="layer-0-offsetted"/>
                    <feMergeNode in="layer-1-offsetted"/>
                    <feMergeNode in="layer-2-offsetted"/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
          </defs>
        </svg>
      </a>
    </div>
  )
}
